import React from 'react';

type Props = {
	src: string;
	alt: string;
	className?: string;
	rounded?: boolean;
};

export const Img: React.FC<Props> = (props) => {
	const { src, alt, className = '', rounded = false } = props;

	let imgClassName = ' inline';

	if (rounded) {
		imgClassName += ' rounded-full w-10 h-10 border-2 border-white -mt-2 -mb-2';
	}

	imgClassName = `${imgClassName.trim()} ${className}`;

	return (<img className={imgClassName} src={src} alt={alt} />);
};
