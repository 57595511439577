import React from 'react';
import { Card } from '../card';

type Props = {
	src: string;
	title?: string;
	children: React.ReactNode;
};

export const IconCard: React.FC<Props> = ({ src, title = '', children }) => (
	<Card className="mt-4">
		<div className="flex">
			<div className="pl-2 pr-6 flex-none flex">
				<img className="w-12" src={src} title={title} alt={title} />
			</div>
			<div className="flex-initial">
				{!!title && (<h3>{title}</h3>)}
				{children}
			</div>
		</div>
	</Card>
);
