import React from 'react';
import { ButtonLink } from '../../../../../common/components/link/button-link';
import { UserContext } from '../../../../contexts/user';
import { Loader } from '../../../../../common/components/loader';
import { UserMenu } from './user-menu';


export const User = () => {
	const { user, loading, signOut } = React.useContext(UserContext);
	
	return (
		<div>
			{loading && (<Loader className="-my-5" />)}
			{(!loading && !!user) && (
				<UserMenu user={user} signOut={signOut} />
			)}
			{(!loading && !user) && (
				<ButtonLink
					className="m-0 py-0.5 px-4"
					href={'/sign-in'}
					title="Sign In"
				/>
			)}
		</div>
	);
};
