
export type LinkCategory = 'telegram' | 'rgbtohex' | 'email' | 'copyright' | 'explorer' | 'patreon';

export class Analytics {
	private static event(
		event: string,
		event_category: string,
		event_label: string
	): void {
		(window as any).gtag('event', event, { event_category, event_label });
	}

	static pageView(url: string): void {
		(window as any).gtag('config', 'UA-113551810-3', { page_path: url });
	}

	static link(category: LinkCategory, label: string): void {
		Analytics.event('link', category, label);
	}

	static login(label: string): void {
		Analytics.event('login', 'login', label);
	}

	static exception(description: string): void {
		(window as any).gtag('event', 'exception', { description });
	}

	static ads(type: string): void {
		Analytics.event('ads', 'ads', type);
	}
}