import React from 'react';
import { NAVIGATION } from '../../../constants/navigation';
import { Link } from '../../../../common/components/link/link';


export const Menu = () => (
	<div className="flex leading-7 my-4 md:my-0">
		<nav className="px-2 flex flex-wrap">
			{NAVIGATION.map(item => (
				<Link key={item.to} href={item.to} title={item.title} className="mx-1" color="white">
					{item.title}
				</Link>
			))}
		</nav>
	</div>
);
