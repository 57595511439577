import React from 'react';
import type firebase from 'firebase';
import { FIREBASE_CONFIG } from '../constants/firebase';

type fApp = firebase.app.App | null;

type Context = {
	firebaseInstance: fApp
};

export const FirebaseContext = React.createContext<Context>({
	firebaseInstance: null
});

type Props = {
	children: React.ReactNode;
};

export const FirebaseContextProvider: React.FC<Props> = ({ children }) => {
	const [firebaseInstance, setFirebaseInstance] = React.useState<fApp>(null);

	const loadFirebaseModules = (): Promise<any> => {
		const firebaseApp = import('firebase/app');
		const firebaseAuth = import('firebase/auth');

		return Promise.all([firebaseApp, firebaseAuth])
			.then(([app]) => app.default)
			.catch(() => console.error('Firebase import failed'));
	};

	React.useEffect(() => {
		if (typeof window === 'undefined')
			return undefined;

		loadFirebaseModules().then(app => {
			const _firebaseInstance = app.initializeApp(FIREBASE_CONFIG);
			setFirebaseInstance(_firebaseInstance);
		});
	}, []);

	const value = React.useMemo(
		() => ({ firebaseInstance }),
		[firebaseInstance]
	);

	return (
		<FirebaseContext.Provider value={value}>
			{children}
		</FirebaseContext.Provider>
	)
};
