import React from 'react';
import { IconCard } from '../../../common/components/icon-card';
import { LayoutHeader } from './header';

type Props = {
	children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({children}) => (
	<div className="min-h-screen">
		<LayoutHeader />
		<div className="container py-12">
			{children}
			<IconCard src="/img/dev.svg">
				Created by <b>Developers</b> for <b>Developers</b>.<br />
				© 2019-{new Date(Date.now()).getFullYear()}
			</IconCard>
		</div>
	</div>
);
