import React from 'react';

type Props = {
	className?: string;
};

export const Loader: React.FC<Props> = ({ className = '' }) => (
	<div className={`flex justify-center items-center flex-grow p-4 ${className}`.trim()}>
		<img className="animate-spin w-8 h-8" src="/img/icon.png" alt="loader" />
	</div>
);

