import React from 'react';

type Props = {
	className?: string;
	children: React.ReactNode;
}

export const Card: React.FC<Props> = ({ className = '', children }) => (
	<div className={`bg-white rounded shadow p-4 overflow-hidden ${className}`.trim()}>
		{children}
	</div>
);
