import React from 'react';
import { Link } from '../../../../../common/components/link/link';
import { Img } from '../../../../../common/components/img';

type Props = {
	user: any;
	signOut: () => void;
};

export const UserMenu: React.FC<Props> = ({ user, signOut }) => (
	<div className="flex ml-4">
		<div className="text-right">
			<div className="text-white leading-none">
				{user.displayName}
			</div>
			<div className="flex leading-none">
				<Link className="text-sm text-yellow-300 pr-1" href="/account">
					Account
				</Link>
				<span className="text-white">/</span>
				<small
					className="text-yellow-300 pl-1 cursor-pointer"
					onClick={() => signOut()}
				>
					Sign Out
				</small>
			</div>
		</div>
		<Img
			className="ml-2"
			src={user.photoURL}
			alt={user.displayName}
			rounded
		/>
	</div>
);
