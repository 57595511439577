import React from 'react';
import type firebase from 'firebase';
import { useToast } from '../../common/hooks/use-toast';
import { TOAST } from '../../common/text/toast';
import { Analytics } from '../../common/utils/analytics';
import { FirebaseContext } from './firebase';

type Context = {
	user: firebase.User | null;
	loading: boolean;
	signOut: () => void;
};

export const UserContext = React.createContext<Context>({
	user: null,
	loading: true,
	signOut: () => {}
});

type Props = {
	children: React.ReactNode;
};

export const UserContextProvider: React.FC<Props> = ({ children }) => {
	const { show, error } = useToast();
	const { firebaseInstance } = React.useContext(FirebaseContext);
	const [user, setUser] = React.useState<firebase.User | null>(null);
	const [loading, setLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (!firebaseInstance) return undefined;

		const authSubscription = firebaseInstance.auth()
			.onAuthStateChanged((_user) => {
				setUser(_user);
				setLoading(false);

				if (_user && _user.providerData[0]) {
					Analytics.login(_user.providerData[0].providerId);
				}

				show('👋', TOAST.HI, _user ? TOAST.WELCOME : TOAST.WELCOME_LOGIN);
			});

		return () => authSubscription();
	}, [firebaseInstance]);


	const signOut = React.useCallback(() => {
		if (!firebaseInstance)
			return null;

		return firebaseInstance.auth().signOut()
			.then(() => show('😉', TOAST.BYE, TOAST.S_OUT_SUCCESS))
			.catch(() => error(TOAST.S_OUT_ERROR));
	}, [firebaseInstance]);

	const value = React.useMemo(
		() => ({ user, loading, signOut }),
		[user, loading, signOut]
	);

	return (
		<UserContext.Provider value={value}>
			{children}
		</UserContext.Provider>
	);
};
