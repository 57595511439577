import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';

import { Layout } from '../modules/app/components/layout/layout';
import { FirebaseContextProvider } from '../modules/app/contexts/firebase';
import { UserContextProvider } from '../modules/app/contexts/user';
import { Analytics } from '../modules/common/utils/analytics';

import 'firebaseui/dist/firebaseui.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles.css';
import 'tailwindcss/tailwind.css';

const App = ({ Component, pageProps }: AppProps): React.ReactNode => {
	const router = useRouter()

	React.useEffect(() => {
		function handleRouteChange(url: string) {
			Analytics.pageView(url);
		}

		router.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		};
	}, [router.events])

	return (
		<React.Fragment>
			<Head>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />

				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
			</Head>
			<FirebaseContextProvider>
				<UserContextProvider>
					<Layout>
						<Component {...pageProps} />
						<ToastContainer
							position="top-left"
							autoClose={5000}
							closeOnClick
							pauseOnHover
						/>
					</Layout>
				</UserContextProvider>
			</FirebaseContextProvider>
		</React.Fragment>
	);
}

export default App;
