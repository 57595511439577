import React from 'react';
import { toast } from 'react-toastify';

export const useToast = () => {
	function layout(
		emoji: string, title: string, text: string
	): React.ReactNode {
		return (
			<div className="flex">
				<div className="text-4xl mr-2 self-center">{emoji}</div>
				<div>
					<b>{title}</b>
					<div className="text-sm">
						{text}
					</div>
				</div>
			</div>
		);
	}

	function show(emoji: string, title: string, text: string): void {
		toast(layout(emoji, title, text));
	}

	function error(text: string): void {
		toast(layout('⚠️', 'Error occurred', text));
	}

	return { show, error };
};
