import React from 'react';
import { Link } from './link';

type Props = {
	className?: string;
	href: string;
	title: string;
}

export const ButtonLink: React.FC<Props> = (props) => {
	const { className = '', href, title } = props;

	return (
		<Link
			className={`btn ${className}`.trim()}
			href={href}
			title={title}
		>
			{title}
		</Link>
	);
}
