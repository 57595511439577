import React from 'react';
import { Logo } from './logo';
import { Menu } from './menu';
import { User } from './user';


export const LayoutHeader = () => (
	<header className="border-b border-white py-4">
		<div className="container justify-between flex flex-wrap flex-col	items-center md:flex-row md:items-start">
			<Logo />
			<Menu />
			<User />
		</div>
	</header>
);
