import React from 'react';
import Link from 'next/link';
import { Img } from '../../../../common/components/img';


export const Logo = () => (
	<Link href="/">
		<a className="text-lg tracking-widest	text-white">
			<Img className="mr-2" src="/img/icon.png" alt="dummy api" rounded />
			DUMMYAPI.IO
		</a>
	</Link>
);
