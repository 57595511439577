import React from 'react';
import { default as NextLink } from 'next/link';

type Props = {
	className: string;
	href: string;
	title?: string;
	color?: string;
	children: React.ReactNode;
}

export const Link: React.FC<Props> = (props) => {
	const { className = '', href, children, title, color = '' } = props;

	let linkClassName = `${className} `;

	if (color === 'white') {
		linkClassName += 'font-light text-white'
	}

	linkClassName = linkClassName.trim();

	return (
		<NextLink href={href} prefetch={false} passHref>
			<a className={linkClassName} title={title}>
				{children}
			</a>
		</NextLink>
	);
}
